import { useCallback, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'

import { googleMapAutoCompletion, goolgeMapGetPlaceDetail } from 'state/ducks/locations/apis'

export const usePlaceAutoCompletion = () => {
  const [searchText, setSearchText] = useState<string>('')
  const [places, setPlaces] = useState<any[]>([])

  const searchPlaces = useCallback(async (text: string) => {
    if (!text) return

    const result = await googleMapAutoCompletion(text)

    if (result.status === 'OK') {
      setPlaces(result.predictions)
    }
  }, [])

  const debouncedSearch = useMemo(() => {
    return debounce((text: string) => searchPlaces(text), 1000)
  }, [searchPlaces])

  const getGeocode = useCallback(async (placeId: string) => {
    const result = await goolgeMapGetPlaceDetail(placeId)

    if (result.status === 'OK') {
      const {
        result: { geometry },
      } = result
      return geometry.location
    }

    return null
  }, [])

  useEffect(() => {
    if (searchText) {
      debouncedSearch(searchText)
    } else {
      debouncedSearch.cancel()
      setPlaces([])
    }
  }, [debouncedSearch, searchText])

  return {
    places,
    searchText,
    setSearchText,
    setPlaces,
    getGeocode,
  }
}
