import { FC } from 'react'
import styles from './ModalCloseButtonComponent.module.scss'

interface Props {
  onPressCloseModalRequest: () => void
  closeButtonStyle?: string
}

const ModalCloseButtonComponent: FC<Props> = ({
  onPressCloseModalRequest,
  closeButtonStyle,
}: Props): JSX.Element => {
  return (
    <div
      className={`${styles.defaultCloseButton} ${closeButtonStyle}`}
      onClick={onPressCloseModalRequest}
    >
      <span />
      <span />
    </div>
  )
}

export default ModalCloseButtonComponent
