import { VFC } from 'react'
import styles from './RatingComponent.module.scss'

interface Props {
  averageRating: number
}

const RatingComponent: VFC<Props> = ({ averageRating }: Props) => {
  const rating = String(averageRating).replace(/[.]/g, '-')

  const classNameStr = `ratingStar${rating}`

  return (
    <div className={styles.ratingBox}>
      <span className={`${styles.ratingStar} ${styles[classNameStr]}`} />
    </div>
  )
}

export default RatingComponent
