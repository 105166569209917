import { ReactNode } from 'react'
import Modal from 'react-modal'
import styles from './ModalLayout.module.scss'

interface Props {
  children: ReactNode
  openModal: boolean
  onPressCloseModalRequest: () => void
  modalStyle: string
  overlayClassName?: string
  shouldCloseOnOverlayClick?: boolean
}

const ModalLayout: React.FC<Props> = ({
  children,
  openModal,
  onPressCloseModalRequest,
  modalStyle,
  overlayClassName,
  shouldCloseOnOverlayClick,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={openModal}
      className={modalStyle}
      onRequestClose={onPressCloseModalRequest}
      overlayClassName={overlayClassName || styles.modalOverlay}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {children}
    </Modal>
  )
}

export default ModalLayout
