import { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { Coordinate } from 'state/ducks/space/models'

import ModalLayout from 'components/commons/layouts/ModalLayout'
import ModalCloseButton from 'components/buttons/ModalCloseButtonComponent'
import { usePlaceAutoCompletion } from 'hooks/locations/usePlaceAutoCompletion'

import styles from './SearchAreaModal.module.scss'

interface Props {
  isShow: boolean
  defaultAreaName: string
  hideSearchCurrentPosition?: boolean
  onPressCloseModalRequest: () => void
  onPressApplyButton: (areaName: string, latLng: Coordinate) => void
  onPressCurrentPosition: () => void
}

const SearchAreaModal: React.FC<Props> = ({
  isShow,
  defaultAreaName,
  hideSearchCurrentPosition = false,
  onPressCloseModalRequest,
  onPressApplyButton,
  onPressCurrentPosition,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const [latLng, setLatLng] = useState<Coordinate>({ lat: 0, lon: 0 })

  const { places, setPlaces, searchText, setSearchText, getGeocode } = usePlaceAutoCompletion()
  const [inputText, setInputText] = useState<string>('')

  useEffect(() => {
    if (defaultAreaName) {
      setInputText(defaultAreaName)
      setSearchText(defaultAreaName)
    }
  }, [defaultAreaName, setSearchText])

  useEffect(() => {
    if (!isShow) {
      setPlaces([])
      setSearchText('')
      setInputText('')
    }
  }, [isShow, setPlaces, setSearchText])

  const _handleSelect = useCallback(
    async (place) => {
      setInputText(place.description)
      setSearchText('')
      setPlaces([])

      const geocode = await getGeocode(place.place_id)

      if (geocode) {
        const { lat, lng } = geocode
        setLatLng({ lat: lat, lon: lng })
      }
    },
    [getGeocode, setPlaces, setSearchText]
  )

  return (
    <ModalLayout
      openModal={isShow}
      onPressCloseModalRequest={onPressCloseModalRequest}
      modalStyle={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modalContainer}>
        <ModalCloseButton
          onPressCloseModalRequest={onPressCloseModalRequest}
          closeButtonStyle={styles.modalCloseButton}
        />
        <div className={styles.modalTitle}>{t('COMMON.AREA')}</div>
        <div className={styles.modalContent}>
          <div className={styles.areaBox}>
            <label className={styles.areaLabel}>{t('SPACES.SEARCH.AREA.PLACEHOLDER')}</label>
            <div className={styles.areaInputBox}>
              <input
                type="text"
                className={styles.areaInputBoxInput}
                placeholder={t('SPACES.SEARCH.AREA_MODAL.ENTER_AREA_NAME')}
                value={inputText}
                onChange={(event) => {
                  const text = event.target.value
                  setSearchText(text)
                  setInputText(text)
                }}
              />
            </div>
          </div>
          <ul className={styles.areaChoiceList}>
            {!hideSearchCurrentPosition && (
              <li className={styles.areaChoiceItem}>
                <button
                  type="button"
                  className={styles.areaChoiceButton}
                  onClick={onPressCurrentPosition}
                >
                  <img
                    className={`${styles.areaChoiceIcon} ${styles.areaChoiceIconCurrent}`}
                    src="/current_location_icon.svg"
                    alt={t('ALT.SEARCH_CURRENT_LOCATION_ICON')}
                  />
                  <span className={styles.areaChoiceText}>
                    {t('TOP.BUTTONS.SEARCH_CURRENT_LOCATION')}
                  </span>
                </button>
              </li>
            )}

            {!!searchText && !!places.length && (
              <>
                {places.map((suggestion) => (
                  <li
                    key={suggestion.place_id}
                    onClick={() => _handleSelect(suggestion)}
                    className={styles.areaChoiceItem}
                  >
                    <button type="button" className={styles.areaChoiceButton}>
                      <img
                        className={`${styles.areaChoiceIcon} ${styles.areaChoiceIconMapPin}`}
                        src="/map_pin_icon_navy_dark.svg"
                        alt={t('META.NOT_MATCHING_SPACE_ICON')}
                      />
                      <span className={styles.areaChoiceText}>{suggestion.description}</span>
                    </button>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
        <button
          className={styles.modalApplyButton}
          onClick={() => {
            onPressApplyButton(inputText, latLng)
          }}
        >
          {t('COMMON.DO_APPLY')}
        </button>
      </div>
    </ModalLayout>
  )
}

export default SearchAreaModal
