let browser = ''
let os = ''

if (typeof window !== 'undefined') {
  const ua = window.navigator.userAgent.toLowerCase()

  if (ua.indexOf('edge') !== -1 || ua.indexOf('edga') !== -1 || ua.indexOf('edgios') !== -1) {
    browser = 'edge'
  } else if (ua.indexOf('opera') !== -1 || ua.indexOf('opr') !== -1) {
    browser = 'opera'
  } else if (ua.indexOf('samsungbrowser') !== -1) {
    browser = 'samsung_browser'
  } else if (ua.indexOf('ucbrowser') !== -1) {
    browser = 'uc_browser'
  } else if (ua.indexOf('chrome') !== -1 || ua.indexOf('crios') !== -1) {
    browser = 'chrome'
  } else if (ua.indexOf('firefox') !== -1 || ua.indexOf('fxios') !== -1) {
    browser = 'firefox'
  } else if (ua.indexOf('safari') !== -1) {
    browser = 'safari'
  } else if (ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1) {
    browser = 'ie'
  }
  if (ua.indexOf('windows nt') !== -1) {
    os = 'windows'
  } else if (ua.indexOf('android') !== -1) {
    os = 'android'
  } else if (ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1) {
    os = 'ios'
  } else if (ua.indexOf('mac os x') !== -1) {
    os = 'mac'
  }
}

export const browserName = browser

export const osName = os
