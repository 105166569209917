import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import styles from './LuggageButtonComponent.module.scss'

interface Props {
  sizeStepper: number
  setSizeStepper: Function
  count?: number
  buttonStyle?: string
}

const LuggageButtonComponent: FC<Props> = ({
  sizeStepper,
  setSizeStepper,
  count,
  buttonStyle,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className={styles.stepper}>
      <button
        className={`${styles.countDownButton} ${buttonStyle}`}
        onClick={() => setSizeStepper(sizeStepper - 1)}
        disabled={sizeStepper > count || sizeStepper === 0}
      >
        <img
          src="/stepper_decrement_icon.svg"
          alt={t('META.STEPPER_DECREMENT_ICON')}
          width={14}
          height={3}
        />
      </button>
      <div className={styles.count}>{sizeStepper}</div>
      <button
        className={`${styles.countDownButton} ${buttonStyle}`}
        onClick={() => setSizeStepper(sizeStepper + 1)}
        disabled={sizeStepper >= count || sizeStepper === 200}
      >
        <img
          src="/stepper_increment_icon.svg"
          alt={t('META.STEPPER_INCREMENT_ICON')}
          width={12}
          height={12}
        />
      </button>
    </div>
  )
}

export default LuggageButtonComponent
