import { FC, useState } from 'react'
import { FocusedInputShape, isInclusivelyAfterDay, DayPickerRangeController } from 'react-dates'
import { useTranslation } from 'next-i18next'

import moment from 'moment'
moment.locale('ja')

import hourList from 'data/hour_list.json'

import styles from './DateRangeCalendarComponent.module.scss'

interface Props {
  locale: string
  isShow: boolean
  isShowTimePicker?: boolean
  onPressCloseModalRequest: () => void
  selectedDate: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }
  setSelectedDate: ({ startDate, endDate }) => void
  selectedDateTime?: {
    startDateTime: string | null
    endDateTime: string | null
  }
  setSelectedDateTime?: ({ startDateTime, endDateTime }) => void
}

const DateRangeCalendarComponent: FC<Props> = ({
  locale,
  isShow,
  isShowTimePicker,
  onPressCloseModalRequest,
  selectedDate,
  setSelectedDate,
  selectedDateTime,
  setSelectedDateTime,
}: Props): JSX.Element => {
  const [focused, setFocused] = useState<FocusedInputShape>('startDate')
  const { t } = useTranslation()

  return (
    <>
      {isShow && (
        <DayPickerRangeController
          startDate={selectedDate.startDate}
          endDate={selectedDate.endDate}
          onDatesChange={({ startDate, endDate }) => {
            setSelectedDate({ startDate: startDate, endDate: endDate })
          }}
          navPrev={<div className={styles.prev} />}
          navNext={<div className={styles.next} />}
          isOutsideRange={(day) =>
            day.isSameOrAfter(moment().add(3, 'month')) || !isInclusivelyAfterDay(day, moment())
          }
          hideKeyboardShortcutsPanel={true}
          focusedInput={focused}
          onFocusChange={(focusedInput) => {
            setFocused(!focusedInput ? 'startDate' : focusedInput)
          }}
          monthFormat={locale === 'ja' ? 'YYYY[年]MM[月]' : 'YYYY/MM'}
          onOutsideClick={() => {
            onPressCloseModalRequest()
          }}
          minimumNights={0}
          initialVisibleMonth={null}
          renderCalendarInfo={() => {
            if (!isShowTimePicker) {
              return <></>
            }

            return (
              <div className={styles.timePicker}>
                <div className={styles.timePickerContainer}>
                  <div className={styles.timePickerTimes}>
                    <div className={styles.timePickerTime}>
                      <strong className={styles.timePickerTimeTitle}>
                        {t('SPACES.SEARCH.CHECK_IN_TIME')}
                      </strong>
                      <select
                        className={styles.timePickerTimeSelect}
                        value={selectedDateTime.startDateTime}
                        onChange={(e) => {
                          selectedDateTime.startDateTime = e.target.value
                          setSelectedDateTime({
                            startDateTime: selectedDateTime.startDateTime,
                            endDateTime: selectedDateTime.endDateTime,
                          })
                        }}
                      >
                        {hourList.map((item, index) => (
                          <option key={index} value={item.hour}>
                            {item.hour}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={styles.timePickerTime}>
                      <strong className={styles.timePickerTimeTitle}>
                        {t('SPACES.SEARCH.CHECK_OUT_TIME')}
                      </strong>
                      <select
                        className={styles.timePickerTimeSelect}
                        value={selectedDateTime.endDateTime}
                        onChange={(e) => {
                          selectedDateTime.endDateTime = e.target.value
                          setSelectedDateTime({
                            startDateTime: selectedDateTime.startDateTime,
                            endDateTime: selectedDateTime.endDateTime,
                          })
                        }}
                      >
                        {hourList.map((item, index) => (
                          <option key={index} value={item.hour}>
                            {item.hour}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <p className={styles.timePickerDescription}>
                    {t('SPACES.SEARCH.SELECT_ABOUT_TIME')}
                  </p>
                </div>
              </div>
            )
          }}
        />
      )}
    </>
  )
}

export default DateRangeCalendarComponent
